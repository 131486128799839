// ** Reducers Imports
import users from '@src/views/apps/user/store';
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import gyms from '../views/gyms/store/gymsStore';
import packages from '../views/gyms/store/packageStore';
import objects from '../views/gyms/store/objectsStore';

const rootReducer = {
  auth,
  users,
  navbar,
  layout,
  gyms,
  packages,
  objects,
};

export default rootReducer;
