// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// ** Axios Imports
import axios from 'axios';
import IGym from '../types/IGym';
import Transaction from '../types/Transaction';
import { WithId } from '../types/WithId';
import Country from '../types/Country';
import { errorToast, successToast } from '../../../components/ToastMessages';
import GymShopAccessOption from '../types/GymShopAccessOption';

const gyms: WithId<IGym>[] = [];
const transactions: Transaction[] = [];
const countries: Country[] = [];
const shopAccessOptions: GymShopAccessOption[] = [];

export const getData = createAsyncThunk('gyms/getData', async () => {
  gyms.splice(0, gyms.length);
  Object.assign(gyms, []);
  const response = await axios.get(
    `${process.env.REACT_APP_URL_ENV}/admin/gyms`,
  );
  Object.assign(gyms, response.data);
  return {
    data: response.data,
  };
});

export const getTransactions = createAsyncThunk(
  'gyms/getTransactions',
  async (gymId?: string) => {
    transactions.splice(0, transactions.length);
    Object.assign(transactions, []);
    const response = await axios.get(
      `${process.env.REACT_APP_URL_ENV}/admin/transactions/gyms/${gymId}`,
    );
    Object.assign(transactions, response.data);
    return {
      data: response.data,
    };
  },
);

export const getCountries = createAsyncThunk('gyms/getCountries', async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_ENV}/country`,
    );
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const getShopAccessOptions = createAsyncThunk(
  'gyms/getShopAccessOptions',
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_ENV}/admin/gyms/shopAccessOptions`,
      );
      return {
        data: response.data,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(err);
    }
  },
);

export const filterTransactions = createAsyncThunk(
  'gyms/filterTransactions',
  async (search: string) => ({
    filterTransactions: transactions.filter((t: Transaction) => JSON.stringify(t).toLowerCase()
      .includes(search.toLowerCase())),
  }),
);

export const addGym = async (values: IGym) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL_ENV}/admin/gyms`,
      values,
    );
    successToast('Klub je uspešno dodat!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const fiscalizationRefund = async (transactionId: number) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_ENV}/refund/${transactionId}`,
    );
    successToast('Refundacija je uspešno izvršena!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const updateGym = async (values: IGym, gymId?: string) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_URL_ENV}/admin/gyms/${gymId}`,
      values,
    );
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const deleteGym = async (gymId: string) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_URL_ENV}/admin/gyms/${gymId}`,
    );
    successToast('Podaci o klubu su uspešno obrisani!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const getGymById = createAsyncThunk(
  'gyms/getById',
  async (gymId?: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_ENV}/admin/gyms/${gymId}`,
    );
    return {
      data: response.data,
    };
  },
);

export const filterData = createAsyncThunk(
  'gyms/filterGyms',
  async (search: string) => ({
    filterGyms: gyms.filter((g: WithId<IGym>) => JSON.stringify(g).toLowerCase()
      .includes(search.toLowerCase())),
  }),
);

const initState: WithId<IGym>[] = [];
const initTransactions: Transaction[] = [];

export const gymsSlice = createSlice({
  name: 'gyms',
  initialState: {
    data: initState,
    selected: null,
    currentGym: null,
    transactions: initTransactions,
    countries,
    shopAccessOptions,
  },
  reducers: {
    selectedGym: (state, action) => {
      if (action.payload === null) {
        state.selected = null;
      } else {
        state.selected = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data;
    });
    builder.addCase(getShopAccessOptions.fulfilled, (state, action) => {
      state.shopAccessOptions = action.payload.data;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload.data;
    });
    builder.addCase(filterData.fulfilled, (state, action) => {
      state.data = action.payload.filterGyms;
    });
    builder.addCase(getGymById.fulfilled, (state, action) => {
      state.currentGym = action.payload.data;
    });
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.transactions = action.payload.data;
    });
    builder.addCase(filterTransactions.fulfilled, (state, action) => {
      state.transactions = action.payload.filterTransactions;
    });
  },
});

export const { selectedGym } = gymsSlice.actions;

export default gymsSlice.reducer;
