// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';
import IPackage from '../types/IPackage';
import { WithId } from '../types/WithId';
import { errorToast, successToast } from '../../../components/ToastMessages';

const packages:WithId<IPackage>[] = [];

export const getPackages = createAsyncThunk(
  'packages/getPackage', async (gymId:string) => {
    try {
      packages.splice(0, packages.length);
      const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/admin/gyms/${gymId}/packages`);
      Object.assign(packages, response.data);
      return {
        data: response.data,
      };
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

export const filterPackages = createAsyncThunk('packages/filterPackage',
  async (search:string) => (
    {
      filterPackage:
      packages.filter(
        (p:WithId<IPackage>) => JSON.stringify(p).toLowerCase().includes(search.toLowerCase()),
      ),
    }));

export const addPackage = async (gymId:string, values:IPackage) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/admin/gyms/${gymId}/packages`, values);
    successToast('Paket je uspešno dodat!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const updatePackage = async (packageId:string, values:IPackage) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/admin/packages/${packageId}`, values);
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const deletePackage = async (packageId:string) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/admin/packages/${packageId}`);
    successToast('Paket je uspešno obrisan!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

const initState:WithId<IPackage>[] = [];

export const packagesSlice = createSlice({

  name: 'packages',
  initialState: {
    data: initState,
    selected: null,
  },
  reducers: {
    selectPackage: (state, action) => {
      if (action.payload === null) {
        state.selected = null;
      } else {
        state.selected = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPackages.fulfilled, (state, action) => {
      state.data = action.payload.data;
    });
    builder.addCase(filterPackages.fulfilled, (state, action) => {
      state.data = action.payload.filterPackage;
    });
  },
});

export const { selectPackage } = packagesSlice.actions;

export default packagesSlice.reducer;
