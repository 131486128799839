// ** Logo
import logo from '@src/assets/images/backgrounds/logo-light.svg'

const SpinnerComponent = () => (
  <div className="fallback-spinner app-loader">
    <img className="fallback-logo" src={logo} alt="logo" width={130} />
    <div className="loading">
      <div className="effect-1 effects" />
      <div className="effect-2 effects" />
      <div className="effect-3 effects" />
    </div>
  </div>
)

export default SpinnerComponent
