// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// ** Axios Imports
import axios from 'axios';
import { WithId } from '../types/WithId';
import IObject from '../components/objects/types/IObject';
import { errorToast, successToast } from '../../../components/ToastMessages';

export const getObjects = createAsyncThunk('objects/getObjects', async (gymId?:string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/admin/gyms/${gymId}/objects`);
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const addObject = async (gymId:string, values: IObject) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/admin/gyms/${gymId}/objects`, values);
    successToast('Objekat je uspešno kreiran!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const updateObject = async (objectId:string, values:IObject) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/admin/gyms/objects/${objectId}`, values);
    successToast('Podaci o objektu su uspešno ažurirani!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const deleteObject = async (objectId: string) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/admin/gyms/objects/${objectId}`);
    successToast('Objekat je uspešno obrisan!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

const initState: WithId<IObject>[] = [];

export const gymsSlice = createSlice({
  name: 'objects',
  initialState: {
    data: initState,
    currentObject: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getObjects.fulfilled, (state, action) => {
      state.data = action.payload.data;
    });
  },
});

export default gymsSlice.reducer;
