import { Check, X } from 'react-feather';
import ToastWrapper from './ToastWrapper';

export const successToast = (title: string) => ToastWrapper(
  'Izmene su sačuvane!',
  <>{title}</>,
  <Check size={12} />,
  'success',
);

export const errorToast = (message?:string) => ToastWrapper(
  'Oops!',
  <>
    <b>Došlo je do greške!{' '}
      <span role="img" aria-label="emoji">😖</span>
    </b>
    <br />
    <hr />
    {message ?? 'Izgleda da se dogodila greška, molimo Vas da pokušate ponovo ili se vratite kasnije!'}
  </>,
  <X size={12} />,
  'danger',
);
